<template>
    <form-field>
        <fieldset>
            <legend
                v-if="props.label"
                class="form-label"
            >
                {{ props.label }}
            </legend>

            <ul>
                <li
                    v-for="(field, index) in fields"
                    :key="field.key"
                    class="p-4 mb-4 last:mb-0 rounded-lg border border-dashed border-gray-300 dark:border-gray-700"
                >
                    <slot
                        name="item"
                        :fields="fields"
                        :field="field"
                        :field-key="field.key"
                        :index="index"
                        :remove="remove"
                    />
                </li>

                <li>
                    <button
                        type="button"
                        class="block w-full border border-dashed border-gray-400 dark:border-gray-600 rounded text-sm py-2 px-4 hover:bg-black/5 dark:hover:bg-white/5"
                        @click.prevent="onAdd"
                    >
                        {{ props.addNewLabel }}
                    </button>
                </li>
            </ul>
        </fieldset>

        <form-field-errors :errors="errors" />
    </form-field>
</template>

<script setup lang="ts">
    import { type FieldEntry, useFieldArray } from 'vee-validate';

    export interface Props {
        label?: string;
        addNewLabel?: string;
        errors?: string[] | string;
        path: string;
        defaultObject: Record<string, unknown> | ((fields: Ref<FieldEntry[]>) => Record<string, unknown>);
    }

    const props = withDefaults(defineProps<Props>(), {
        label: undefined,
        addNewLabel: 'Add new',
        errors: undefined,
    });

    const emits = defineEmits<{
        add: [string | number]
    }>();

    const { remove, push, fields } = useFieldArray(props.path);

    const onAdd = async() => {
        const defaultObject = typeof props.defaultObject === 'function'
            ? props.defaultObject(fields)
            : props.defaultObject;

        push({
            ...defaultObject,
        });

        await nextTick();

        const lastField = fields.value[fields.value.length - 1];
        const lastFieldKey = lastField.key;

        emits('add', lastFieldKey);
    };
</script>
